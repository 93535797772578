<template>
  <div class="admin-links-list">
    <loading-box v-bind:loading="loading"></loading-box>
<!--    <div class="filter-bar">-->
<!--      <div class="green-label">Filters</div>-->
<!--      <search-filters-->
<!--        :filters="filters"-->
<!--        @change="changeFilter"-->
<!--        @press-enter="applyFilter"-->
<!--        ref="searchFilters"-->
<!--      ></search-filters>-->
<!--      <div>-->
<!--        <b-button-->
<!--          @click="applyFilter">Apply</b-button>-->
<!--        <b-button-->
<!--          @click="resetFilters">Reset</b-button>-->
<!--      </div>-->

<!--    </div>-->
    <div class="green-divider"></div>
    <!--    Bootstrap Table-->
    <b-table striped hover :items="linksList" :fields="linkFields" selected-variant="success"
             th-class="bv-table-header" :current-page="currentPage" :per-page="perPage"
             :filter="filterMethod === 'local' ? filter : undefined"
             :filter-included-fields="filterFields"
             tbody-tr-class="table-body-centered"
             @row-clicked="onRowSelected"
             class="bv-table" ref="linksTable">

      <template v-slot:cell(actions)="row">
        <i
            class="row-icon-button-danger"
            :class="$config.icons.general.remove"
            v-b-tooltip.hover
            title="Delete"
            v-if="!row.item.disable_delete"
            @click="deleteLink(row.item, $event)"
        ></i>
      </template>
    </b-table>
    <div class="green-divider"></div>
        <div class="footer">
          <div class="table-results">
            Results: {{totalRows}}
          </div>
          <div class="page-bar">
            <b-form-select
              variant="dark"
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              class="page-select">
            </b-form-select>
            <b-pagination
              variant="dark"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="page-buttons"></b-pagination>
          </div>
        </div>
  </div>
</template>

<script>
import LoadingBox from '@/components/helpers/LoadingBox'
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as AlertHelper from '@/components/helpers/AlertHelper'
import moment from 'moment'
import {adminDeleteLink} from '@/components/helpers/DataProvider'
// import SearchFilters from '@/components/shared/SearchFilters.vue'


export default {
  name: 'admin-links-list',
  components: {
    LoadingBox
  },
  props: {
    links: Array
  },
  data: function () {
    return {
      loading: true,
      filterMethod: 'local', // Filter Locally or remotely at the server-side
      filter: '',
      filterFields: null,
      // Table Pagination
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 0, text: 'All' }
      ],
      linkFields: [
        { key: 'id',
          label: 'Id',
          sortable: true
        },
        { key: 'link_code',
          label: 'Link Code',
          sortable: true
        },
        { key: 'published',
          label: 'Published',
          sortable: true
        },
        { key: 'name',
          label: 'Name',
          sortable: true
        },
        { key: 'activity_type',
          label: 'Activity Type',
          sortable: true
        },
        { key: 'created',
          label: 'Created',
          sortable: true
        },
        { key: 'updated',
          label: 'updated',
          sortable: true
        },
        { key: 'owner_id',
          label: 'Owner',
          sortable: true
        },
        { key: 'actions',
          label: 'Actions'
        }
      ],
      filters: [
        {
          label: 'Filter By Status',
          type: 'select',
          placeholder: 'Status...',
          fieldName: 'status',
          options: [
            { value: 'COMPLETE', text: 'Complete' },
            { value: 'FAILED', text: 'Failed' },
            { value: 'INPROG', text: 'In Progress' },
            { value: 'QUEUED', text: 'Queued' },
            { value: 'NEW', text: 'New' }
          ]
        },
        {
          label: 'Filter By Owner',
          type: 'string',
          placeholder: 'Owner Id',
          fieldName: 'owner_id'
        }
      ],
      currentFilter: {},
      linksList: []
    }
  },
  async created () {
    if (this.links) {
      this.linksList = this.links
    } else {
      this.linksList = this.getLinks
    }
    this.loading = false
  },
  methods: {
    applyFilter: function () {
      this.refresh()
    },
    changeFilter: function (filters) {
      console.log('Set Filters: ', filters)
      this.currentFilter = filters
    },
    resetFilters: function () {
      this.$refs.searchFilters.reset()
      this.refresh()
    },
    refresh () {
      this.$refs.linksTable.refresh()
    },
    async getLinks (ctx) {
      let resp = await DataProvider.adminGetLinks((ctx.currentPage - 1) * ctx.perPage, ctx.perPage)
      if (!resp.success) {
        ErrorHelper.displayDataErrorToast(resp)
      } else {
        if (resp.data.hasOwnProperty('total')) {
          this.totalRows = resp.data.total
        }
        return resp.data.links
      }
    },
    formatTimestamp: function (timestamp) {
      return moment.utc(timestamp).local().format('YYYY-MM-DD HH:mm')
    },
    async deleteLink(link, event) {
      event.stopPropagation()
      let confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this link?')

      if (confirm) {
        let resp = await DataProvider.adminDeleteLink(link.id)
        if (resp.success) {
          AlertHelper.successToast('Link Deleted', 'Form Deleted')
          this.refresh()
        } else {
          ErrorHelper.displayDataErrorToast(resp)
        }
      }
    },
    onSave: async function () {
      this.refresh()
    },
    onRowSelected (row) {
      console.log('sel')
      this.$emit('row-selected', row)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .task-list{
    position: relative;
  }

  .filter-bar {
    display: flex;
    flex-direction: row;
    align-content: center;
  }

  .page-bar {
    display: flex;
    flex-direction: row;
  }

  .page-select {
    width: 10%;
    min-width: 50px;
  }

  .page-buttons {
    width: 25%;
    min-width: 150px;
  }

  .row-action {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .child-card {
    background: $theme-color-background-4;
    padding: 10px 20px;
    border: 2px solid $theme-color-primary-3;
  }

  .advanced-filter{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
    padding: 3px;
    background: $theme-color-background-1;
    border: 1px solid $theme-color-primary-3;
  }

  .filter-label {
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0px 5px;
  }

  .sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    height: calc(1.5em + 0.5rem + 2px);
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 20px;
  }

  .table-results {
    color: $theme-color-primary-4;
    font-weight: 600;
  }
</style>
