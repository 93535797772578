<template>
  <div class="admin-forms">
    <div class="toolbar justify-content-between">
      <div>
        <i class="icon-button" :class="$config.icons.general.back" v-b-tooltip title="Back to Admin" @click="clickBack"></i>
      </div>
      <div>
        <i class="mx-1 icon-button" :class="$config.icons.admin.links" v-b-tooltip title="New Data Link" @click="createLink"></i>
        <i class="icon-button" :class="$config.icons.general.refresh" v-b-tooltip title="Refresh" @click="refreshList"></i>
<!--        <i class="mx-1 icon-button" :class="$config.icons.user.add" v-b-tooltip title="Add a User" @click="createUser"></i>-->
<!--        <router-link to="/admin/users/bulkadd"><i class="mx-1 icon-button" :class="$config.icons.user.bulkAdd" v-b-tooltip title="Upload Users"></i></router-link>-->
        <!--        <i class="fa fa-user-times mx-1 icon-button" v-b-tooltip title="Delete Selected Users" @click="deleteSelected"></i>-->
      </div>
    </div>
    <div class="list-containter">
      <b-card>
        <b-card-header><h2>Data Links Admin</h2></b-card-header>
        <b-card-body>
          <AdminLinksList ref="formsList" @row-selected="selectLinkData"></AdminLinksList>
        </b-card-body>
      </b-card>
    </div>
    <!--    Create Edit Modal Template-->
    <b-modal id="modal-edit-form-data" centered class="modal-content" size="xl" hide-footer title="View Form">
      <b-tabs>
        <b-tab title="Form">
          <FormEdit :form="selectedForm"></FormEdit>
        </b-tab>
        <b-tab title="Auditlog">
          <AuditLog v-if="selectedForm" related_type="form" :related_id="selectedForm.id"></AuditLog>
        </b-tab>
      </b-tabs>

    </b-modal>
  </div>
</template>
<script>

import FormEdit from '@/components/forms/FormEdit.vue'
import AuditLog from '@/components/shared/AuditLogList.vue'
import AdminLinksList from '@/components/admin/AdminLinksList.vue'

export default {
  name: 'admin-links',
  components: {
    AdminLinksList,
    AuditLog,
    FormEdit
  },
  data: function () {
    return {
      selectedUsers: [],
      selectedForm: null
    }
  },
  methods: {
    clickBack: function () {
      this.$router.push({ path: '/admin' })
    },
    selectLinkData (linkData) {
      console.log('Selected: ', linkData)
      this.$router.push({ path: `/admin/links/${linkData.id}` })
    },
    createLink: function () {
      this.$router.push({ path: `/admin/links/new` })
    },
    refreshList: function () {
      this.$refs.formsList.refresh()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../variables';

  .admin-forms {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2%;
    overflow-y: auto;
  }

  .list-containter {
    background: $theme-color-background-4;
    border-radius: 5px;
    width: 100%;
    padding: 5px;
    margin: 5px;
  }

  .toolbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 3rem;
  }

</style>
