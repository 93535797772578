var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "admin-links-list" },
    [
      _c("loading-box", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "green-divider" }),
      _c("b-table", {
        ref: "linksTable",
        staticClass: "bv-table",
        attrs: {
          striped: "",
          hover: "",
          items: _vm.linksList,
          fields: _vm.linkFields,
          "selected-variant": "success",
          "th-class": "bv-table-header",
          "current-page": _vm.currentPage,
          "per-page": _vm.perPage,
          filter: _vm.filterMethod === "local" ? _vm.filter : undefined,
          "filter-included-fields": _vm.filterFields,
          "tbody-tr-class": "table-body-centered"
        },
        on: { "row-clicked": _vm.onRowSelected },
        scopedSlots: _vm._u([
          {
            key: "cell(actions)",
            fn: function(row) {
              return [
                !row.item.disable_delete
                  ? _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "row-icon-button-danger",
                      class: _vm.$config.icons.general.remove,
                      attrs: { title: "Delete" },
                      on: {
                        click: function($event) {
                          return _vm.deleteLink(row.item, $event)
                        }
                      }
                    })
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "green-divider" }),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "table-results" }, [
          _vm._v(" Results: " + _vm._s(_vm.totalRows) + " ")
        ]),
        _c(
          "div",
          { staticClass: "page-bar" },
          [
            _c("b-form-select", {
              staticClass: "page-select",
              attrs: {
                variant: "dark",
                id: "perPageSelect",
                size: "sm",
                options: _vm.pageOptions
              },
              model: {
                value: _vm.perPage,
                callback: function($$v) {
                  _vm.perPage = $$v
                },
                expression: "perPage"
              }
            }),
            _c("b-pagination", {
              staticClass: "page-buttons",
              attrs: {
                variant: "dark",
                "total-rows": _vm.totalRows,
                "per-page": _vm.perPage,
                align: "fill",
                size: "sm"
              },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }